import http from '@/http.js'
import qs from 'query-string'

export class SurveyService {
  static getSurveys () {
    return http.get('/surveys/current_school_year')
  }

  static getNextQuestion (data) {
    return http.get('/surveys/next_question?' + qs.stringify({
      survey_year_period_id: data.survey_year_period_id
    }))
  }

  static getQuestionBySequence (data) {
    return http.get('/surveys/question_by_sequence?' + qs.stringify({
      survey_year_period_id: data.survey_year_period_id,
      sequence: data.sequence
    }))
  }
}
