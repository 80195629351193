import { ResultService } from '@/services/result.service'

const state = {
  results: null
}

const getters = {
  results: state => state.results
}

const actions = {
  async getResults ({ commit }) {
    return await ResultService.getResults().then(response => {
      commit('SET_RESULTS', response)
      return response
    })
  },
}

const mutations = {
  SET_RESULTS: (state, payload) => {
    state.results = payload.data
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
