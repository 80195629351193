import { SurveyService } from '@/services/survey.service.js'
import { encryptStorage } from '@/constants.js'

const state = {
  surveys: null,
  currentSurveyYearPeriodId: null,
  currentQuestion: null,
  audio: encryptStorage.getItem('audio') ? true : false
}

const getters = {
  surveys: state => state.surveys,
  currentSurveyYearPeriodId: state => state.currentSurveyYearPeriodId,
  currentQuestion: state => state.currentQuestion,
  audio: state => state.audio
}

const actions = {
  async getSurveys ({ commit }) {
    await SurveyService.getSurveys().then(response => {
      if (typeof response.response === "undefined") {
        commit('SET_SURVEYS', response.data[0])
      }
    })
  },
  async getNextQuestion ({ commit }, data) {
    await SurveyService.getNextQuestion(data).then(response => {
      if (typeof response.response === "undefined") {
        commit('SET_QUESTION', response.data[0])
      }
    })
  },
  async getQuestionBySequence ({ commit }, data) {
    await SurveyService.getQuestionBySequence(data).then(response => {
      if (typeof response.response === "undefined") {
        commit('SET_QUESTION', response.data[0])
      }
    })
  },
  setSurveyYearPeriodId ({ commit }, data) {
    commit('SET_SURVEY_YEAR_PERIOD_ID', data)
  },
  questionCompleted ({ commit }, data) {
    commit('QUESTION_COMPLETED', data)
  },
  clearSurveys({ commit }) {
    commit('CLEAR_SURVEYS')
  },
  setAudio ({ commit }, data) {
    commit('SET_AUDIO', data)
  }
}

const mutations = {
  SET_SURVEYS: (state, payload) => {
    state.surveys = payload.data
  },
  SET_QUESTION: (state, payload) => {
    state.currentQuestion = payload.data
  },
  SET_SURVEY_YEAR_PERIOD_ID: (state, payload) => {
    state.currentSurveyYearPeriodId = payload
  },
  QUESTION_COMPLETED: (state, payload) => {
    const index = state.surveys.indexOf(state.surveys.find(survey => survey.periods[0].survey_year_period_id === payload.survey_year_period_id));
    if (payload.complete) {
      state.surveys[index].periods[0].response_status = "COMPLETE"
    }
    state.surveys[index].periods[0].response_count++
  },
  CLEAR_SURVEYS: (state) => {
    state.surveys = null
    state.currentSurveyYearPeriodId = null
    state.currentQuestion = null
  },
  SET_AUDIO: (state, payload) => {
    state.audio = payload
    if (payload) encryptStorage.setItem('audio', payload)
    else encryptStorage.removeItem('audio')
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
